import { j as a, T as _t, c as C, s as xt, L as ft, H as wt, I as kt, B as gt, a as pt, b as jt } from "./TextLink-D2CCL_5L.mjs";
import { A as Xe, d as Je, C as Ye, J as Ge, e as Qe, f as Ve } from "./TextLink-D2CCL_5L.mjs";
import * as V from "react";
import { forwardRef as K, useMemo as z, useState as O, useRef as F, useCallback as j, useEffect as I, useId as P, useLayoutEffect as mt } from "react";
import { createPortal as Tt } from "react-dom";
const Ct = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), rt = {}, Lt = (e, t, n, o) => {
  const s = `${e}-${t ? "currency" : "noCurrency"}-${n}-${o}`;
  let i = rt[s];
  return i || (i = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...o ? {} : { useGrouping: !1 }
  }), rt[s] = i), i;
};
function et(e, t, n = {}) {
  const o = (n == null ? void 0 : n.precision) === void 0 ? 2 : n == null ? void 0 : n.precision, r = typeof t == "number" ? t || 0 : Ct(e, t), s = (n == null ? void 0 : n.thousandSeparator) ?? !0;
  return Lt(e, !(n != null && n.format), o, s).format(r).replace(/\u00A0/, " ");
}
function st(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), n = parseFloat(t) || 0;
  return Math.round(n * 100);
}
function Y(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
const Me = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function it(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Nt = K(
  ({
    label: e,
    locale: t = "de",
    name: n,
    id: o,
    min: r = 0,
    onChange: s,
    onBlur: i,
    onFocus: d,
    defaultValue: l,
    value: u,
    step: f,
    children: p,
    className: T,
    hideSteps: L,
    max: g,
    ...h
  }, b) => {
    const x = z(() => vt(f), [f]), [k, y] = O(
      Y(
        et(t, u ?? l ?? "", { precision: x, format: "%v", thousandSeparator: !1 }),
        x
      )
    ), B = z(() => it(g), [g]), c = z(() => it(r), [r]), [m, N] = O(null), M = F(null), G = j(
      (_) => {
        if (M.current = _, !!b) {
          if (typeof b == "function") return b(_);
          b.current = _;
        }
      },
      [b]
    ), w = j(
      (_) => {
        const $ = ct(_.target.value, t, x), Q = st($);
        if (typeof B < "u" && Q > B || typeof c < "u" && Q < c) {
          const bt = _.target.selectionStart ? _.target.selectionStart : 0;
          return N(bt), _.preventDefault();
        }
        N(_.target.selectionStart);
        let nt = _.target.value;
        $.startsWith(_.target.value) || (nt = $);
        const ot = st(_.target.value);
        _.target.value && !isNaN(ot) && Q === ot && (s == null || s(_)), y(nt);
      },
      [t, x, c, B, s]
    ), v = j(
      (_) => {
        const $ = ct(_.target.value, t, x);
        $ !== _.target.value && (_.target.value = $), N(null), i == null || i(_);
      },
      [i, x, t]
    ), A = j(
      (_) => {
        N(_.target.selectionStart), d == null || d(_);
      },
      [d]
    ), W = z(
      () => l ? { defaultValue: k } : { value: k },
      [l, k]
    );
    return I(() => {
      if (typeof l < "u") return;
      const _ = u ?? l;
      if (typeof u > "u")
        return y("");
      const $ = Y(
        et(t, _, { precision: x, format: "%v", thousandSeparator: !1 }),
        x
      );
      y($);
    }, [u, x, l, t]), I(() => {
      const _ = (k == null ? void 0 : k.length) ?? 0, $ = (m ?? 0) > _ ? _ : m;
      m === null || !M.current || document.activeElement !== M.current || M.current.setSelectionRange($, $);
    }, [m, k]), /* @__PURE__ */ a.jsx(
      _t,
      {
        ...h,
        ...W,
        onFocus: A,
        onBlur: v,
        onChange: w,
        step: f,
        ref: G,
        id: o || n,
        name: n,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: C(T, { [xt.hideSteps]: L }),
        children: p
      }
    );
  }
);
Nt.displayName = "ClientAmountField";
function ct(e, t, n = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const o = String(e), r = Y(o, n), s = et(t, r, { precision: n, format: "%v", thousandSeparator: !1 }), i = Y(s, n);
  if (!n)
    return i;
  const d = i.search(/[.,]/);
  if (d < 0)
    return i;
  const l = n - (i.length - 1 - d);
  return i.concat("0".repeat(l));
}
function vt(e) {
  var n;
  return e === void 0 ? void 0 : ((n = String(e).split(".")[1]) == null ? void 0 : n.length) ?? 0;
}
const yt = "_checkmark_ciy57_1", $t = "_checkboxContainer_ciy57_23", At = "_labelContent_ciy57_39", It = "_checkboxWrapper_ciy57_79", Bt = "_checkbox_ciy57_23", Mt = "_disabledLabel_ciy57_89", E = {
  checkmark: yt,
  checkboxContainer: $t,
  labelContent: At,
  checkboxWrapper: It,
  checkbox: Bt,
  disabledLabel: Mt
}, at = (e) => {
  e.preventDefault();
}, Oe = K(function({
  id: t,
  name: n,
  error: o = !1,
  classNames: r,
  children: s,
  required: i,
  checked: d,
  isDisabled: l,
  onChange: u,
  onClick: f,
  disabled: p,
  ...T
}, L) {
  const g = P(), h = t || n || g, b = l || p;
  return /* @__PURE__ */ a.jsx(
    "label",
    {
      htmlFor: h,
      className: C(E.checkboxContainer, r == null ? void 0 : r.label, {
        [E.disabledLabel]: b
      }),
      children: /* @__PURE__ */ a.jsxs("div", { className: E.checkboxWrapper, children: [
        /* @__PURE__ */ a.jsxs("div", { className: E.checkbox, children: [
          /* @__PURE__ */ a.jsx(
            "input",
            {
              type: "checkbox",
              tabIndex: 0,
              ...T,
              ref: L,
              id: h,
              name: n,
              required: i,
              onClick: b ? at : f,
              onChange: b ? at : u,
              "aria-disabled": b,
              "aria-required": i,
              "aria-invalid": o,
              "aria-checked": d,
              "aria-describedby": o ? `${n}-error` : void 0
            }
          ),
          /* @__PURE__ */ a.jsx("span", { className: C(E.checkmark, r == null ? void 0 : r.checkmark) })
        ] }),
        /* @__PURE__ */ a.jsx("div", { className: C(E.labelContent, r == null ? void 0 : r.labelContent), children: s })
      ] })
    }
  );
}), Ot = "_fieldset_hid4k_1", Ft = "_fieldContainer_hid4k_30", Dt = "_groupLabel_hid4k_34", Pt = "_labelLarge_hid4k_39", Wt = "_groupHint_hid4k_43", Et = "_groupError_hid4k_48", Rt = "_errorMessage_hid4k_57", qt = "_disabledLabels_hid4k_64", H = {
  fieldset: Ot,
  fieldContainer: Ft,
  groupLabel: Dt,
  labelLarge: Pt,
  groupHint: Wt,
  groupError: Et,
  errorMessage: Rt,
  disabledLabels: qt
}, zt = "_modal_36g8m_1", Ht = "_modalInner_36g8m_51", Ut = "_modalHeader_36g8m_55", Kt = "_closeButton_36g8m_70", Xt = "_closeIcon_36g8m_76", Jt = "_modalContent_36g8m_80", Yt = "_modalActions_36g8m_84", R = {
  modal: zt,
  modalInner: Ht,
  modalHeader: Ut,
  closeButton: Kt,
  closeIcon: Xt,
  modalContent: Jt,
  modalActions: Yt
};
function Fe({
  id: e,
  isOpen: t,
  onClose: n,
  onOpen: o,
  onCloseComplete: r,
  onOpenComplete: s,
  title: i,
  customActions: d,
  children: l,
  manualFocusRef: u,
  closeText: f,
  closeOnClickOutside: p = !0,
  hideHeader: T = !1,
  hideActions: L = !1,
  classNames: g = {}
}) {
  const h = F(null), b = F(null), [x, k] = O(!0), y = j(() => u || (b.current ? b : h), [u]), B = P(), c = e || B, m = j((w) => {
    var _;
    if (w.key !== "Tab") return;
    const v = (_ = h.current) == null ? void 0 : _.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ), A = v == null ? void 0 : v[0], W = v == null ? void 0 : v[v.length - 1];
    if (w.shiftKey && document.activeElement === A) {
      w.preventDefault(), W == null || W.focus();
      return;
    }
    !w.shiftKey && document.activeElement === W && (w.preventDefault(), A == null || A.focus());
  }, []), N = j(() => {
    r == null || r(), k(!0);
  }, [r]), M = j(() => {
    var w;
    s == null || s(), k(!0), (w = h.current) == null || w.removeAttribute("inert");
  }, [s]);
  I(() => {
    var v;
    k(!1);
    const w = h.current;
    if (w) {
      if (t) {
        w.showModal();
        const A = y();
        (v = A == null ? void 0 : A.current) == null || v.focus(), w.removeEventListener("transitionend", N), w.addEventListener("keydown", m), w.addEventListener("transitionend", M), o == null || o();
      } else
        w.removeEventListener("keydown", m), w.removeEventListener("transitionend", M), w.addEventListener("transitionend", N), w.close(), w.setAttribute("inert", "");
      return () => {
        w == null || w.removeEventListener("keydown", m), w.removeEventListener("transitionend", N), w.removeEventListener("transitionend", M);
      };
    }
  }, [y, m, t, N, o, M]);
  const G = j(
    (w) => {
      const { current: v } = h;
      w.target === v && n();
    },
    [n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ a.jsx(
      "dialog",
      {
        id: c,
        ref: h,
        className: C(R.modal, g.modal),
        onCancel: n,
        onClick: p ? G : void 0,
        "aria-labelledby": `modal-title-${c}`,
        "aria-modal": "true",
        title: i,
        tabIndex: -1,
        children: /* @__PURE__ */ a.jsxs(ft, { space: "400", className: C(R.modalInner, g.modalInner), children: [
          !T && /* @__PURE__ */ a.jsxs("div", { className: C(R.modalHeader, g.titleContainer), children: [
            /* @__PURE__ */ a.jsx(wt, { level: "h200", as: "h2", id: `modal-title-${c}`, children: i }),
            /* @__PURE__ */ a.jsx(
              kt,
              {
                kind: "plain",
                className: R.closeButton,
                onClick: n,
                ref: b,
                type: "button",
                iconName: "x",
                iconProps: { title: f, color: "fg-content-primary" }
              }
            )
          ] }),
          /* @__PURE__ */ a.jsx("div", { className: C(R.modalContent, g.contentContainer), children: (t || !x) && l }),
          !L && /* @__PURE__ */ a.jsx("div", { className: C(R.modalActions, g.actionsContainer), children: d || /* @__PURE__ */ a.jsx(gt, { onClick: n, type: "button", children: f }) })
        ] })
      }
    )
  );
}
const Gt = "_cluster_1iwsd_1", Qt = "_clustered_1iwsd_7", Vt = "_justified_1iwsd_11", Z = {
  cluster: Gt,
  clustered: Qt,
  justified: Vt
};
function Zt({
  as: e = "div",
  alignment: t = "clustered",
  className: n,
  style: o,
  space: r = "200",
  ...s
}) {
  return /* @__PURE__ */ a.jsx(
    e,
    {
      ...s,
      className: C(Z.cluster, n, {
        [Z.justified]: t === "justified",
        [Z.clustered]: t === "clustered"
      }),
      style: {
        ...o,
        "--space": `var(--betterplace-spacing-${r})`
      },
      children: s.children
    }
  );
}
function St({ className: e, hint: t, required: n, children: o }) {
  return /* @__PURE__ */ a.jsxs("legend", { className: e, children: [
    /* @__PURE__ */ a.jsxs("div", { className: H.groupLabel, children: [
      o,
      n && /* @__PURE__ */ a.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    t ? /* @__PURE__ */ a.jsx("div", { className: H.groupHint, children: t }) : null
  ] });
}
function De({
  children: e,
  classNames: t,
  legend: n,
  hint: o,
  required: r,
  error: s,
  layout: i = "horizontal",
  layoutProps: d = {
    space: "none"
  },
  errorDisplay: l = "all",
  ...u
}) {
  const f = C(H.fieldContainer, d == null ? void 0 : d.className, {
    [H.groupError]: !!s && l !== "text"
  }), p = i === "vertical" ? ft : Zt, T = P(), L = u.id || T, g = s ? `${L}-error` : void 0;
  return /* @__PURE__ */ a.jsxs(
    "fieldset",
    {
      id: L,
      className: C(H.fieldset, t == null ? void 0 : t.fieldset),
      "aria-describedby": g,
      ...u,
      children: [
        n || o ? /* @__PURE__ */ a.jsx(St, { required: r, hint: o, className: t == null ? void 0 : t.legend, children: n }) : null,
        /* @__PURE__ */ a.jsx(p, { className: f, ...d, children: e }),
        s && l !== "border" ? /* @__PURE__ */ a.jsx("p", { id: g, className: H.errorMessage, "aria-live": "polite", role: "alert", children: s }) : null
      ]
    }
  );
}
const te = "_selectContainer_12ifg_1", ee = "_select_12ifg_1", ne = "_label_12ifg_41", oe = "_hint_12ifg_48", X = {
  selectContainer: te,
  select: ee,
  label: ne,
  hint: oe
}, Pe = K(function({ id: t, name: n, children: o, label: r, hint: s, required: i, ...d }, l) {
  const u = P(), f = t || n || u;
  return /* @__PURE__ */ a.jsxs("div", { children: [
    /* @__PURE__ */ a.jsx("div", { children: r ? /* @__PURE__ */ a.jsxs("label", { htmlFor: f, className: C(X.label), id: `${f}-label`, children: [
      /* @__PURE__ */ a.jsxs("div", { children: [
        r,
        i && /* @__PURE__ */ a.jsx("span", { "aria-label": "(required)", children: "*" })
      ] }),
      s ? /* @__PURE__ */ a.jsx("div", { className: C(X.hint), id: `${f}-hint`, children: s }) : null
    ] }) : null }),
    /* @__PURE__ */ a.jsxs("div", { className: X.selectContainer, children: [
      /* @__PURE__ */ a.jsx("select", { ref: l, className: X.select, id: f, name: n, ...d, children: o }),
      /* @__PURE__ */ a.jsx(pt, { name: "arrowDown" })
    ] })
  ] });
}), re = "_radioContainer_p0kaq_1", se = "_label_p0kaq_16", ie = "_radioCircle_p0kaq_26", ce = "_radioWrapper_p0kaq_54", ae = "_radio_p0kaq_1", de = "_disabledLabel_p0kaq_64", q = {
  radioContainer: re,
  label: se,
  radioCircle: ie,
  radioWrapper: ce,
  radio: ae,
  disabledLabel: de
}, le = (e) => /* @__PURE__ */ V.createElement("svg", { width: 27, height: 28, viewBox: "0 0 27 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...e }, /* @__PURE__ */ V.createElement("circle", { cx: 13.5, cy: 14.21, r: 12.9375, fill: "white", stroke: "#919191", strokeWidth: 1.125 }), /* @__PURE__ */ V.createElement("circle", { cx: 13.4882, cy: 14.2689, r: 6.75, fill: "#357C00" })), dt = (e) => {
  e.preventDefault();
}, We = K(function({ children: t, isDisabled: n = !1, checked: o, classNames: r, disabled: s, ...i }, d) {
  const { value: l, name: u, id: f, onChange: p, onClick: T } = i, L = P(), h = `${f || u || L}-${String(l)}`, b = n || s;
  return /* @__PURE__ */ a.jsx(
    "label",
    {
      htmlFor: h,
      className: C(q.radioContainer, r == null ? void 0 : r.container, {
        [q.disabledLabel]: b
      }),
      children: /* @__PURE__ */ a.jsxs("div", { className: q.radioWrapper, children: [
        /* @__PURE__ */ a.jsxs("div", { className: q.radio, children: [
          /* @__PURE__ */ a.jsx(
            "input",
            {
              ...i,
              id: h,
              type: "radio",
              ref: d,
              "aria-disabled": b,
              "aria-checked": o,
              onClick: b ? dt : T,
              onChange: b ? dt : p
            }
          ),
          /* @__PURE__ */ a.jsx(le, { className: C(q.radioCircle, r == null ? void 0 : r.radioCircle) })
        ] }),
        /* @__PURE__ */ a.jsx("div", { className: C(q.label, r == null ? void 0 : r.label), children: t })
      ] })
    }
  );
}), ue = "_input_xcovg_1", fe = "_growWrap_xcovg_2", J = {
  input: ue,
  growWrap: fe,
  "input--error": "_input--error_xcovg_24"
}, Ee = K(function({ label: t, name: n, description: o, error: r, warning: s, id: i, required: d, className: l, onChange: u, errorDisplay: f, rows: p = 1, ...T }, L) {
  const g = F(null), h = [];
  return r && h.push(`${n}-error`), s && h.push(`${n}-warning`), o && h.push(`${n}-description`), /* @__PURE__ */ a.jsx(
    jt,
    {
      label: t,
      name: n,
      description: o,
      error: r,
      warning: s,
      id: i || n,
      required: d,
      errorDisplay: f,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ a.jsx("div", { className: C(J.growWrap, l, { [J.wrapError]: !!r }), ref: g, children: /* @__PURE__ */ a.jsx(
        "textarea",
        {
          ...T,
          ref: L,
          id: i || n,
          name: n,
          "aria-describedby": h.length ? h.join(" ") : void 0,
          "aria-invalid": !!r,
          "aria-required": d,
          className: C(J.input, l, { [J["input--error"]]: !!r }),
          rows: p,
          onChange: (b) => {
            var x;
            (x = g.current) == null || x.setAttribute("data-replicated-value", b.target.value), u && u(b);
          }
        }
      ) })
    }
  );
}), pe = "_button_1vjsc_1", me = "_hiddenInput_1vjsc_59", S = {
  button: pe,
  hiddenInput: me,
  "size-large": "_size-large_1vjsc_65",
  "size-default": "_size-default_1vjsc_70",
  "size-small": "_size-small_1vjsc_75"
}, lt = (e) => {
  e.preventDefault();
}, Re = K(function({
  id: t,
  children: n,
  value: o,
  name: r,
  onChange: s,
  onClick: i,
  className: d,
  type: l = "radio",
  size: u = "default",
  isDisabled: f = !1,
  disabled: p,
  ...T
}, L) {
  const g = C(S.button, S[`size-${u}`], d), h = P(), x = `${t || r || h}-${String(o)}`, k = f || p;
  return /* @__PURE__ */ a.jsxs("label", { htmlFor: x, className: g, children: [
    /* @__PURE__ */ a.jsx(
      "input",
      {
        ...T,
        ref: L,
        id: x,
        "aria-disabled": k,
        type: l,
        value: o,
        name: r,
        onClick: k ? lt : i,
        onChange: k ? lt : s,
        className: S.hiddenInput
      }
    ),
    n
  ] });
}), he = "_tooltipContent_1e6gc_1", be = "_tooltipContainer_1e6gc_11", _e = "_tooltipArrow_1e6gc_19", xe = "_bottomTooltipArrow_1e6gc_50", we = "_topTooltipArrow_1e6gc_54", ke = "_spyElement_1e6gc_58", ge = "_triggerContainer_1e6gc_64", U = {
  tooltipContent: he,
  tooltipContainer: be,
  tooltipArrow: _e,
  bottomTooltipArrow: xe,
  topTooltipArrow: we,
  spyElement: ke,
  triggerContainer: ge
}, D = {
  TOP: "top",
  BOTTOM: "bottom"
};
function je(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function ht(e) {
  return (t) => t === e || t && je(t, e) ? t : e;
}
const tt = {
  enter: "Enter",
  space: " ",
  tab: "Tab",
  escape: "Escape"
}, Te = ["INPUT", "SELECT", "TEXTAREA", "A", "BUTTON", "AREA"];
function ut({ x: e, position: t }) {
  return /* @__PURE__ */ a.jsx(
    "div",
    {
      className: C(U.tooltipArrow, {
        [U.bottomTooltipArrow]: t === D.BOTTOM,
        [U.topTooltipArrow]: t === D.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === D.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
function Ce({
  triggerRect: e,
  id: t,
  onTooltipLeave: n,
  children: o,
  triggerEvents: r
}) {
  const s = F(null), [i, d] = O(null), [l, u] = O(null), f = j(() => {
    var y;
    const k = ((y = s == null ? void 0 : s.current) == null ? void 0 : y.getBoundingClientRect()) ?? null;
    d(ht(k && { width: k.width, height: k.height }));
  }, []), p = j(() => {
    const k = document.body.getBoundingClientRect();
    u(k.width);
  }, []);
  mt(() => {
    f(), p();
  }, [f, p]);
  const T = j(() => {
    window.requestAnimationFrame(() => {
      f(), p();
    });
  }, [f, p]), L = j(
    (k) => {
      r != null && r.includes("hover") && n(k);
    },
    [n, r]
  );
  I(() => (window.addEventListener("resize", T), () => {
    window.removeEventListener("resize", T);
  }), [T]);
  const { x: g, y: h, arrowTooltipOffsetX: b, position: x } = z(
    () => Ne(e, i, l),
    [e, i, l]
  );
  return Tt(
    /* @__PURE__ */ a.jsxs(
      "div",
      {
        id: t,
        role: "status",
        className: U.tooltipContainer,
        ref: s,
        onPointerLeave: L,
        style: {
          transform: `translate3d(${g}px, ${h}px, 0)`
        },
        children: [
          x === D.BOTTOM && /* @__PURE__ */ a.jsx(ut, { position: x, x: b }),
          /* @__PURE__ */ a.jsx("div", { className: U.tooltipContent, children: o }),
          x === D.TOP && /* @__PURE__ */ a.jsx(ut, { position: x, x: b })
        ]
      }
    ),
    document.body
  );
}
function Le({ children: e, content: t, id: n, triggerEvents: o }) {
  const r = P(), s = n || r, i = F(null), [d, l] = O(null);
  I(() => {
    var m, N;
    const c = (m = i.current) == null ? void 0 : m.nextSibling;
    c && (!Te.includes(c.tagName) && c.tagName !== "LABEL" && (c == null || c.setAttribute("tabindex", "0"), c == null || c.setAttribute("role", "button")), c.tagName === "LABEL" ? (N = c.querySelector("input")) == null || N.setAttribute("aria-describedby", s) : c == null || c.setAttribute("aria-describedby", s));
  }, [e, s]);
  const u = j(() => {
    var N;
    const c = (N = i == null ? void 0 : i.current) == null ? void 0 : N.nextSibling;
    let m = (c == null ? void 0 : c.getBoundingClientRect()) ?? null;
    m != null && m.height || (m = null), l(ht(m && { left: m.left, top: m.top, bottom: m.bottom, width: m.width }));
  }, [i]), f = j(() => {
    d && u();
  }, [u, d]), p = j(() => {
    l(null);
  }, []), T = j(() => {
    d ? p() : u();
  }, [u, p, d]), L = j(
    (c) => {
      const m = document.getElementById(r);
      if (c.relatedTarget instanceof Node && (m != null && m.contains(c.relatedTarget)))
        return c.preventDefault();
      p();
    },
    [p, r]
  ), g = j(
    (c) => {
      var m, N;
      if (c.relatedTarget instanceof Node && ((N = (m = i == null ? void 0 : i.current) == null ? void 0 : m.nextSibling) != null && N.contains(c.relatedTarget)))
        return c.preventDefault();
      p();
    },
    [p]
  ), h = j(
    (c) => {
      o != null && o.includes("click") && (c.preventDefault(), T());
    },
    [T, o]
  ), b = j(
    (c) => {
      if (o != null && o.includes("keypress") && (c.key === tt.enter || c.key === tt.space)) {
        c.preventDefault(), T();
        return;
      }
      c.key === tt.escape && p();
    },
    [p, T, o]
  ), x = j(() => {
    o != null && o.includes("focus") && u();
  }, [u, o]), k = j(() => {
    o != null && o.includes("focus") && p();
  }, [p, o]), y = j(() => {
    o != null && o.includes("hover") && u();
  }, [u, o]), B = j(
    (c) => {
      o != null && o.includes("hover") && L(c);
    },
    [L, o]
  );
  return I(() => {
    var m;
    const c = (m = i.current) == null ? void 0 : m.nextSibling;
    return c == null || c.addEventListener("click", h), c.addEventListener("pointerenter", y), c.addEventListener("pointerleave", B), c.addEventListener("focus", x), c.addEventListener("focusin", x), c.addEventListener("focusout", k), c.addEventListener("keydown", b), () => {
      c.removeEventListener("click", h), c.removeEventListener("pointerenter", y), c.removeEventListener("pointerleave", B), c.removeEventListener("focus", x), c.removeEventListener("focusin", x), c.removeEventListener("focusout", k), c.removeEventListener("keydown", b);
    };
  }, [h, x, b, y, B, T, i, k, e]), I(() => (window.addEventListener("resize", f), () => {
    window.removeEventListener("resize", f);
  }), [f]), mt(() => {
    f();
  }, [e, f]), /* @__PURE__ */ a.jsxs(a.Fragment, { children: [
    /* @__PURE__ */ a.jsx("div", { ref: i, className: U.spyElement }),
    e,
    d && /* @__PURE__ */ a.jsx(
      Ce,
      {
        triggerRect: d,
        id: s,
        onTooltipLeave: g,
        triggerEvents: o,
        children: t
      }
    )
  ] });
}
function qe({ children: e, content: t, id: n, triggerEvents: o = ["hover", "focus"], inactive: r }) {
  const [s, i] = O(!1);
  return I(() => (i(!0), () => {
    i(!1);
  }), []), !s || r ? e : /* @__PURE__ */ a.jsx(Le, { content: t, id: n, triggerEvents: o, children: e });
}
function Ne(e, t, n, o = 20, r = 17) {
  let s = 0, i = 0, d = 0, l = 0, u = D.TOP;
  if (!e || !t || !n) return { x: s, y: i, arrowTooltipOffsetX: l, position: u };
  const f = t.width > n ? 0 : Math.min(o, (n - t.width) / 2), p = t.width / 2, T = e.width / 2;
  s = e.left - p + T;
  const L = s;
  d = s + t.width, s < f ? s = f : d > n - f && (s -= d - (n - f)), l = L - s + p - r;
  const g = l + 2 * r - t.width;
  if (g > 0) {
    const h = Math.min(g, f);
    s += h, l -= h;
  }
  return i = e.top - t.height, i < 0 && (u = D.BOTTOM, i = e.bottom), { x: s, y: i, arrowTooltipOffsetX: l, position: u };
}
const ve = "_tooltipTrigger_p1pqu_1", ye = {
  tooltipTrigger: ve
}, ze = ({ type: e = "question", title: t = "More information", className: n }) => /* @__PURE__ */ a.jsx("button", { className: C(ye.tooltipTrigger, n), type: "button", children: /* @__PURE__ */ a.jsx(pt, { name: e, title: t }) });
function $e(e, t, n) {
  const o = F(null), r = F(null), [s, i] = O(null);
  return I(() => {
    r.current = t ?? null;
  }, [t]), I(() => (o.current = new MutationObserver((d) => {
    for (const l of d)
      (typeof r.current != "function" || r.current(l)) && i(l);
  }), o.current.observe(e.current, n), () => {
    var d;
    (d = o.current) == null || d.disconnect();
  }), []), s;
}
function He(e, t) {
  const n = j(
    (s) => s.type === "attributes" && s.attributeName === t,
    [t]
  ), o = $e(e, n, { attributes: !0 });
  return z(
    () => {
      var s;
      return { oldValue: (o == null ? void 0 : o.oldValue) ?? null, newValue: (s = e.current) == null ? void 0 : s.getAttribute(t) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [o, t]
  );
}
export {
  Xe as Alert,
  Me as AllowedInputTypes,
  Nt as AmountField,
  Je as Badge,
  gt as Button,
  Ye as Card,
  Oe as Checkbox,
  Pe as Dropdown,
  De as Fieldset,
  wt as Heading,
  pt as Icon,
  kt as IconButton,
  Ge as JSONPrettyPrint,
  Qe as LayoutCenter,
  Zt as LayoutCluster,
  Fe as LayoutModal,
  ft as LayoutStack,
  D as Position,
  We as RadioButton,
  _t as TextInput,
  Ve as TextLink,
  Ee as Textarea,
  Re as ToggleButton,
  qe as Tooltip,
  ze as TooltipTrigger,
  He as useAttributeObserver,
  $e as useMutationObserver
};
